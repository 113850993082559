<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#42275a"
      spinner="spinner"
    />
    <v-layout class="mainfont" wrap justify-center pb-7>
      <v-flex xs11 pt-4>
        <v-layout wrap>
          <v-flex xs12>
            <v-card class="pb-4">
              <v-layout wrap justify-start>
                <v-flex xs12 pl-3 pt-3 pb-4 class="bgcustom">
                  <span style="font-size: 25px; color: white"
                    >Application Number - {{ list.applicationNo }}</span
                  >
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap pb-3>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 lg3 text-left pl-0 pl-lg-3>
                  <span class="subheading" style="font-size: 16px">
                    Applicant Name
                  </span>
                  <br />
                  <span>{{ list.applicantName }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px">
                    District
                  </span>
                  <br />
                  <span>{{ list.applicantDistrict }}</span>
                </v-flex>

                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px">
                    Email
                  </span>
                  <br />
                  <span>{{ list.applicantEmail }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px">
                    Mobile Number
                  </span>
                  <br />
                  <span>{{ list.applicantMobileNo }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pl-0 pl-lg-3 pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Address
                  </span>
                  <br />
                  <span>{{ list.applicantAddress }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Pincode
                  </span>
                  <br />
                  <span>{{ list.applicantPinCode }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Id Type
                  </span>
                  <br />
                  <span>{{ list.idType }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Id Card No
                  </span>
                  <br />
                  <span>{{ list.idcardNo }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4 pl-0 pl-lg-3>
                  <span class="subheading" style="font-size: 16px">
                    Nature Of Application
                  </span>
                  <br />
                  <span>{{ list.natureOfApplication }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Id Proof </span
                  >&nbsp;

                  <v-btn
                    class="bgcustom"
                    dark
                    download
                    :href="baseURL + 'file/get/' + idProof"
                    target="_blank"
                    ><span
                      style="
                        text-transform: none;
                        font-family: mainfont;
                        font-size: 12px;
                      "
                      >View
                    </span></v-btn
                  >
                  <br />
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Progress </span
                  >&nbsp;

                  <v-btn
                    class="bgcustom"
                    dark
                    @click="$router.push('/timeline?id=' + id)"
                    ><span
                      style="
                        text-transform: none;
                        font-family: mainfont;
                        font-size: 12px;
                      "
                      >Progress
                    </span></v-btn
                  >
                  <!-- <v-btn class="bgcustom" dark @click="navigateToTimeline" v-if="id">
                    <span style="text-transform: none; font-family: mainfont; font-size: 12px;">Progress</span>
                  </v-btn> -->

                  <br />
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    Preshoot Order </span
                  >&nbsp;

                  <v-btn
                    class="bgcustom"
                    dark
                    download
                    :href="baseURL + 'file/get/' + Preshootfile"
                    target="_blank"
                    ><span
                      style="
                        text-transform: none;
                        font-family: mainfont;
                        font-size: 12px;
                      "
                      >View
                    </span></v-btn
                  >
                  <br />
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pl-0
                  pl-lg-3
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    Species Name
                  </span>
                  <br />
                  <span>{{ list.species }}</span>
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    No Of Animals
                  </span>
                  <br />
                  <span>{{ list.noOfAnimal }}</span>
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    Preshoot Number
                  </span>
                  <br />
                  <span>{{ list.preshootNumber }}</span>
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    Preshoot Valid From
                  </span>
                  <br />
                  <span>{{ formatDate(list.validityfromDate) }}</span>
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pl-0
                  pl-lg-3
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    Preshoot Valid To
                  </span>
                  <br />
                  <span>{{ formatDate(list.validitytoDate) }}</span>
                </v-flex>
                <v-flex xs12 pa-5>
                  <v-card
                    elevation="0"
                    style="border: 1px solid black; border-radius: 0px"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap class="subheading">
                          <v-flex xs2 text-center pt-2> Location </v-flex>
                          <v-flex xs2 text-center pt-2> Film Name </v-flex>
                          <v-flex xs1 text-center pt-2> From Date </v-flex>
                          <v-flex xs2 text-center pt-2> To Date </v-flex>
                          <v-flex xs2 text-center pt-2> No Of Members </v-flex>
                          <v-flex xs2 text-center pt-2> No Of Vehicles </v-flex>
                          <v-flex xs1 text-center pt-2> No Of Days </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12> </v-flex>
                    </v-layout>
                    <v-layout wrap pt-4 pb-4>
                      <v-flex xs12>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      pb-3
                      wrap
                      v-for="(item, i) in filmShootingDetails"
                      :key="i"
                    >
                      <v-flex xs2 text-center pt-2>
                        {{ item.location }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.filmName }}
                      </v-flex>
                      <v-flex xs1 text-center pt-2>
                        {{ formatDate(item.fromDate) }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ formatDate(item.toDate) }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.noOfMembers }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.noOfVehicles }}
                      </v-flex>
                      <v-flex xs1 text-center pt-2>
                        {{ item.shootingDuration }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs12 pa-5 v-if="list.paymentInitiation === 'YES'">
                  <!-- <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="subheading" style="font-size: 16px;">Payment Details</span>
                    </v-flex>
                  </v-layout> -->
                  <v-card
                    elevation="0"
                    style="border: 1px solid black; border-radius: 0px"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap class="subheading">
                          <v-flex xs2 text-center pt-2> Shooting Fees </v-flex>
                          <v-flex xs2 text-center pt-2>
                            Security Deposit
                          </v-flex>
                          <v-flex xs2 text-center pt-2> Total Amount </v-flex>
                          <v-flex xs2 text-center pt-2> Payment Status </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12> </v-flex>
                    </v-layout>
                    <v-layout wrap pt-4 pb-4>
                      <v-flex xs12>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                    <v-layout pb-3 wrap>
                      <v-flex xs2 text-center pt-2>
                        {{ list.shootingfee }}Rs
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ list.securitydeposit }}Rs
                      </v-flex>
                      <v-flex xs2 text-center pt-2> {{ total }}Rs </v-flex>
                      <v-flex xs2 text-center pt-2>{{
                        list.paymentStatus
                      }}</v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-4>
          <v-flex xs12 v-if="user === 'range'">
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-layout wrap justify-center pa-3>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getApplication()"
                          >
                            <v-icon color="white">mdi-download</v-icon>

                            <span style="color: white; font-size: small">
                              APPLICATION</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD ACKNOWLEDGMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getAcknowledgment()"
                          >
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color: white; font-size: small">
                              ACKNOWLEDGMENT</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.userAgreement"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>VIEW AGREEMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.userAgreement"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              AGREEMENT
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> UPLOAD MAHASAR</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            @click="dialogVisible = true"
                          >
                            <v-icon color="white">mdi-upload</v-icon>

                            <span style="font-size: small; color: white">
                              MAHASAR
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> UPLOAD REPORT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            @click="dialogVisible2 = true"
                          >
                            <v-icon color="white">mdi-upload</v-icon>

                            <span style="font-size: small; color: white">
                              REPORT
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.rangeMahassar && list.rangeReport"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> FORWARD TO CLERK</v-flex>
                        <v-flex xs2 text-center>
                          <v-btn
                            block
                            class="text-capitalize"
                            @click="forwardToClerk2()"
                            color="orange"
                          >
                            <v-icon color="white">mdi-share</v-icon>&nbsp;
                            <span style="color: white">FORWARD</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 v-if="user === 'user'">
            <v-card v-if="list.status === 'Active'">
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-layout wrap justify-center pa-3>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.cancelRequest === 'No'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>CANCEL APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize"
                            @click="deletedialog = true"
                            color="red"
                          >
                            <v-icon color="white">mdi-cancel</v-icon>
                            <span style="color: white; font-size: small"
                              >CANCEL</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.cancelRequest === 'Yes'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs5 text-left style="color: red"
                          >REQUESTED FOR CANCELLATION</v-flex
                        >
                        <!-- <v-flex xs2>
                            <v-btn block class="text-capitalize" @click="(deletedialog = true)" color="red">
                              <v-icon color="white">mdi-cancel</v-icon>
                              <span style="color:white;font-size: small;">CANCEL</span>
                            </v-btn>
                          </v-flex> -->
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getApplication()"
                          >
                            <v-icon color="white">mdi-download</v-icon>

                            <span style="color: white; font-size: small">
                              APPLICATION</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD ACKNOWLEDGMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getAcknowledgment()"
                          >
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color: white; font-size: small">
                              ACKNOWLEDGMENT</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <!-- <v-flex xs12 lg11 text-center v-if="list.userAgreement" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>VIEW AGREEMENT</v-flex>
                        <v-flex xs2>

                          <v-btn block class="bgcustom" dark download :href="baseURL + 'file/get/' + list.userAgreement"
                            target="_blank">
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span style="text-transform: none; font-family: mainfont; font-size: 12px;">
                              AGREEMENT
                            </span></v-btn>
                        </v-flex>

                      </v-layout>

                    </v-flex> -->

                    <!-- <v-flex xs12 lg11 text-center v-if="list.paymentInitiation === 'YES'" pa-2>
                        <v-layout wrap justify-center>
                          <v-flex xs3 text-left>UPLOAD AGREEMENT</v-flex>
                          <v-flex xs2>
                            <v-btn block color="#2e8b57" @click="openDialog = true">
                              <v-icon color="white">mdi-upload</v-icon>
  
                              <span style="font-size: small;color: white;">
                                AGREEMENT
                              </span>
  
                            </v-btn>
                          </v-flex>
  
                        </v-layout>
  
                      </v-flex> -->
                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.paymentStatus === 'Success'"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD AGREEMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize"
                            @click="getAgreement()"
                            color="blue"
                          >
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color: white; font-size: small">
                              AGREEMENT
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="
                        list.paymentInitiation === 'YES' &&
                        list.paymentStatus === 'Pending'
                      "
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> FEE PAYMENT</v-flex>
                        <v-flex xs2>
                          <!-- <v-btn block color="#2e8b57" @click="feePayment()">
                            <span style="font-size: small;color: white;">
                              Fee Payment
                            </span>
                          </v-btn> -->
                          <v-btn
                            block
                            color="#2e8b57"
                            @click="submitdialog = true"
                          >
                            <span style="font-size: small; color: white">
                              Fee Payment
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.paymentStatus === 'Success'"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs12 lg12 text-center>
                          <span style="color: #228b22; font-weight: bold"
                            >Payment Done</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      pa-2
                      v-if="
                        list.rangeMahassars.length > 0 &&
                        list.rangeReports.length > 0
                      "
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>PROCEEDINGS ORDER</v-flex>
                        <v-flex xs2>
                          <!-- <v-btn block class="text-capitalize bgcustom" @click="getProceedings()">
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color:white;font-size: small;">PROCEEDINGS ORDER</span>
                          </v-btn> -->
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.proceedings"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              PROCEEDINGS
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex
            xs12
            v-else-if="
              user === 'section' || user === 'superclerk' || user === 'division'
            "
          >
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-layout wrap justify-center pa-3>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.status !== 'Rejected'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>CANCEL APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize"
                            @click="deletedialog = true"
                            color="red"
                          >
                            <v-icon color="white">mdi-cancel</v-icon>
                            <span style="color: white; font-size: small"
                              >CANCEL</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getApplication()"
                          >
                            <v-icon color="white">mdi-download</v-icon>

                            <span style="color: white; font-size: small">
                              APPLICATION</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD ACKNOWLEDGMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getAcknowledgment()"
                          >
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color: white; font-size: small">
                              ACKNOWLEDGMENT</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <!-- <v-flex xs12 lg11 text-center  pa-2>
                        <v-layout wrap justify-center>
                          <v-flex xs3 text-left>UPLOAD AGREEMENT</v-flex>
                          <v-flex xs2>
                            <v-btn block color="#2e8b57" @click="openDialog = true">
                              <v-icon color="white">mdi-upload</v-icon>
  
                              <span style="font-size: small;color: white;">
                                AGREEMENT
                              </span>
  
                            </v-btn>
                          </v-flex>
  
                        </v-layout>
  
                      </v-flex> -->

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.userAgreement"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>VIEW AGREEMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.userAgreement"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              AGREEMENT
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 lg11 text-center v-if="list.rangeReport" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD REPORT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.rangeReport"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-download</v-icon>

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              REPORT
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.rangeMahassar"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD MAHASAR</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.rangeMahassar"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-download</v-icon>

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              MAHASAR
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs2>
            <v-btn color="primary" @click="goBack">
              <span style="text-transform: none; font-size: 15px; color: white">
                GO BACK
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogVisible2" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont"> Upload Report </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input
              class="mainfont"
              v-model="reportFile"
              label="Choose Report file"
            ></v-file-input>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadReport()" color="primary"
            >Yes</v-btn
          >
          <v-btn @click="dialogVisible2 = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVisible" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont"> Upload Magasar </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input
              class="mainfont"
              v-model="mahasarFile"
              label="Choose Mahasar file"
            ></v-file-input>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadMahasar()" color="primary"
            >Yes</v-btn
          >
          <v-btn @click="dialogVisible = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont"> Upload Agreement </span>
        </v-card-title>
        <v-card-text>
          <v-card> </v-card>
          <v-file-input
            class="mainfont"
            v-model="file"
            label="Choose a file"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadAgreement()" color="primary"
            >Yes</v-btn
          >
          <v-btn @click="openDialog = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="deletedialog"
      max-width="600px"
    >
      <v-card>
        <v-layout wrap justify-start>
          <v-flex xs12>
            <v-card-title
              class="mainfont"
              style="color: black; font-size: 18px; font-weight: lighter"
            >
              Are you sure you want to Cancel?
            </v-card-title>
          </v-flex>
          <v-flex xs12 pl-3 pr-3>
            <v-text-field
              type="text"
              dense
              color="#283e51"
              outlined
              background-color="white"
              v-model="reason"
              hide-details
            >
              <template v-slot:label>
                <span
                  class="custom-label-text mainfont"
                  style="color: black; font-size: 14px"
                >
                  Reason For Cancel Request<span
                    style="color: red; font-size: 20px"
                    >*</span
                  >
                </span>
              </template>
            </v-text-field>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-btn
            color="black"
            text
            @click="deletedialog = false"
            class="mainfont"
            >Cancel</v-btn
          >
          <v-btn color="red" class="mainfont" text @click="deleteItem()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="submitdialog"
      max-width="800px"
    >
      <v-card>
        <v-card-title
          class="mainfont"
          style="color: black; font-size: 18px; font-weight: lighter"
          >Please enter the details below and proceed for payment.</v-card-title
        >
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-layout wrap justify-start>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg6>
                    <v-text-field
                      type="text"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentname"
                      :rules="[(v) => !!v || 'Name is required']"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Name
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 lg6 pl-2>
                    <v-text-field
                      type="text"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentaddress"
                      :rules="[(v) => !!v || 'Address is required']"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Address
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg6>
                    <v-text-field
                      type="number"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentphonenumber"
                      :rules="[
                        (v) => !!v || 'Phone number is required',
                        (v) =>
                          /^[0-9]{10}$/.test(v) ||
                          'Phone number must have 10 digits',
                      ]"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Phone Number
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 lg6 pl-2>
                    <v-text-field
                      type="number"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentpincode"
                      :rules="[
                        (v) => !!v || 'Pincode is required',
                        (v) =>
                          /^[0-9]{6}$/.test(v) || 'Pincode must have 6 digits',
                      ]"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Pincode
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg6>
                    <v-text-field
                      type="text"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentpancardnumber"
                      :rules="[
                        (v) => !!v || 'PAN Card Number is required',
                        (v) =>
                          /^[A-Za-z0-9]{10}$/.test(v) ||
                          'PAN Card Number must have exactly 10 characters',
                      ]"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          PAN Card Number
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 lg6 pl-2>
                    <v-text-field
                      type="number"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymenttaxid"
                      :rules="[(v) => !!v || 'TAX Id is required']"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          TAX Id
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="paymentdialog()" class="mainfont"
            >Cancel</v-btn
          >
          <v-btn
            color="green"
            class="mainfont"
            text
            :disabled="submitted"
            @click="feePayment()"
          >
            {{ submitted ? "Processing..." : "Proceed For Payment" }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import axios from "axios";
// import CryptoJS from "crypto-js";
export default {
  components: {},
  data() {
    const today = new Date().toISOString().substr(0, 10);
    //   const encryptedId = this.$route.query.id;
    // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
    return {
      dialogVisible2: false,
      idNo: null,
      startDate: today,
      showSnackBar: false,
      isChecked: false,
      deletedialog: false,
      openDialog: false,
      submitted: false,
      reportFile: null,
      submitdialog: false,
      paymentphonenumber: "",
      paymentpancardnumber: "",
      paymentname: "",
      paymenttaxid: "",
      paymentpincode: "",
      paymentaddress: "",
      file: null,
      mahasarFile: null,
      appLoading: false,
      msg: "",
      id: this.$route.query.id,
      // id:decryptedId,
      name: null,
      dialogVisible: false,
      barlist: [],
      getdetailsdata: "",
      list: {},
      leaderdetails: [],
      idProof: "",
      total: 0,
      Preshootfile: "",
      filmShootingDetails: [],
      reason: "",
    };
  },
  mounted() {
    this.getList();
    this.updateTotal();
  },
  created() {
    this.uType = localStorage.getItem("uType");
  },

  computed: {
    user() {
      const uType = localStorage.getItem("uType");
      if (uType === "777") {
        return "admin";
      } else if (uType === "772") {
        return "user";
      } else if (uType === "773") {
        return "range";
      } else if (uType === "7741") {
        return "section";
      } else if (uType === "7742") {
        return "superclerk";
      } else {
        return "division";
      }
    },
  },
  watch: {
    "list.shootingfee": "updateTotal",
    "list.securitydeposit": "updateTotal",
  },

  methods: {
    //   navigateToTimeline() {
    //   // Encrypt the ID before navigating
    //   const encryptedId = CryptoJS.AES.encrypt(this.id, 'encryptionSecret').toString();
    //   this.$router.push({
    //     path: '/timeline',
    //     query: {
    //       id: encryptedId
    //     }
    //   });
    // },
    goBack() {
      // Use the router to navigate back to the previous page
      this.$router.go(-1);
    },
    updateTotal() {
      this.total =
        (this.list.shootingfee || 0) + (this.list.securitydeposit || 0);
    },
    paymentdialog() {
      this.submitdialog = false;
      this.submitted = false;
      this.paymentphonenumber = "";
      this.paymentname = "";
      this.paymentpancardnumber = "";
      this.paymentaddress = "";
      this.paymentpincode = "";
      this.paymenttaxid = "";
    },
    forwardToClerk2() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      axios({
        url: "/range/forward/clerk",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            // window.location.reload();
            this.$router.push({ path: "/rangebookings" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    uploadAgreement() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      const formData = new FormData();
      formData.append("userAgreement", this.file);
      formData.append("appid", this.id);
      axios
        .post(`/user/agreement`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          this.openDialog = false;
          location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    uploadReport() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      const formData = new FormData();
      formData.append("rangeReport", this.reportFile);
      formData.append("appid", this.id);
      axios
        .post(`/report/upload`, formData, {
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          (this.dialogVisible = false), location.reload();
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    },
    uploadMahasar() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      const formData = new FormData();
      formData.append("rangeMahassar", this.mahasarFile);
      formData.append("appid", this.id);
      axios
        .post(`/mahassar/upload`, formData, {
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          (this.dialogVisible = false), location.reload();
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const selectedFile = event.target.files[0];
      console.log("Selected file:", selectedFile);
    },
    forwardToClerk() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      axios({
        url: "/check/forward",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    feePayment() {
      if (!this.paymentname) {
        this.msg = "Provide Name For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentaddress) {
        this.msg = "Provide Address For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentphonenumber) {
        this.msg = "Provide Phone Number For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentpincode) {
        this.msg = "Provide Pincode For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentpancardnumber) {
        this.msg = "Provide PAN Card Number For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymenttaxid) {
        this.msg = "Provide Tax Id For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[0-9]{10}$/.test(this.paymentphonenumber)) {
        this.msg = "Phone Number must be 10 digits.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[0-9]{6}$/.test(this.paymentpincode)) {
        this.msg = "Pincode must be 6 digits.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[A-Za-z0-9]{10}$/.test(this.paymentpancardnumber)) {
        this.msg =
          "PAN Card Number must have exactly 10 characters.";
        this.showSnackBar = true;
        return;
      }

      this.submitted = true;
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      axios({
        url: "/fees/submission",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
          address: this.paymentaddress,
          mobnumber: this.paymentphonenumber,
          pin_no: this.paymentpincode,
          pan_no: this.paymentpancardnumber,
          tax_id: this.paymenttaxid,
          party_name: this.paymentname,
          amount: this.total,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getdetailsdata = response.data.data;
            this.openFormInNewWindow();
            this.forwardToClerk();
            // this.deletedialog = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.submitted = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
          this.submitted = false;
        });
    },
    openFormInNewWindow() {
      // Create a form element
      const form = document.createElement("form");
      form.method = "POST";
      form.action =
        "https://etreasury.kerala.gov.in/api/eTreasury/service/ChallanGeneration.php"; // Replace with your actual endpoint URL
      form.target = "_blank"; // Open in a new window or tab

      // Create hidden input fields for 'input_data' and 'input_headers'
      const inputDataField = document.createElement("input");
      inputDataField.type = "hidden";
      inputDataField.name = "input_data";
      inputDataField.value = JSON.stringify({
        HMAC: this.getdetailsdata.HMAC, // Replace with actual HMAC value

        DATA: this.getdetailsdata.DATA,

        // Replace with actual encrypted data
      });

      const inputHeadersField = document.createElement("input");
      inputHeadersField.type = "hidden";
      inputHeadersField.name = "input_headers";
      inputHeadersField.value = JSON.stringify({
        clientId: this.getdetailsdata.clientId,
        authToken: this.getdetailsdata.authToken,
        // Replace with actual auth token
      });

      // Append hidden inputs to the form
      form.appendChild(inputDataField);
      form.appendChild(inputHeadersField);

      // Append form to the body (required to submit it)
      document.body.appendChild(form);

      // Submit the form
      form.submit();

      // Remove the form from the DOM after submission
      document.body.removeChild(form);
    },
    deleteItem() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      axios({
        url: "/cancel/application",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
          reason: this.reason,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
            this.$router.push({ path: "/mybooking" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProceedings() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      const token = localStorage.getItem("token");
      const downloadUrl = "/proceedings/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write(
            '<iframe width="100%" height="100%" src="' +
              url +
              '" type="application/pdf"></iframe>'
          );
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },

    getAcknowledgment() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      const token = localStorage.getItem("token");
      const downloadUrl = "/acknowledgement/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write(
            '<iframe width="100%" height="100%" src="' +
              url +
              '" type="application/pdf"></iframe>'
          );
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },
    getApplication() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      const token = localStorage.getItem("token");
      const downloadUrl = "/application/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write(
            '<iframe width="100%" height="100%" src="' +
              url +
              '" type="application/pdf"></iframe>'
          );
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },
    getAgreement() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      const token = localStorage.getItem("token");
      const downloadUrl = "/new/agreement/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "agreement.pdf"; // Specify the filename for download
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },

    // getAgreement() {
    //   const token = localStorage.getItem("token");
    //   const downloadUrl = "/new/agreement/pdf";
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     params: {
    //       appid: this.$route.query.id,
    //     },
    //     responseType: "blob",
    //   };

    //   axios
    //     .get(downloadUrl, config)
    //     .then((response) => {
    //       const blob = new Blob([response.data], { type: 'application/pdf' });
    //       const url = window.URL.createObjectURL(blob);

    //       // Open the URL in a new tab
    //       const newTab = window.open();
    //       newTab.document.write('<iframe width="100%" height="100%" src="' + url + '" type="application/pdf"></iframe>');
    //       newTab.document.close();
    //     })
    //     .catch((error) => {
    //       console.error("Error downloading acknowledgement:", error);
    //     });
    // },

    // getAgreement() {
    //   const token = localStorage.getItem("token");
    //   const downloadUrl = "/agreement/pdf";
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     params: {
    //       appid: this.$route.query.id,
    //     },
    //     responseType: "blob",
    //   };

    //   axios
    //     .get(downloadUrl, config)
    //     .then((response) => {
    //       const blob = new Blob([response.data], { type: 'application/pdf' });
    //       const url = window.URL.createObjectURL(blob);

    //       // Set the window location to the PDF URL
    //       window.location.href = url;
    //     })
    //     .catch((error) => {
    //       console.error("Error downloading acknowledgement:", error);
    //     });
    // },

    getList() {
      this.appLoading = true;
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      axios({
        method: "POST",
        url: "/single/application",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list = response.data.data;
            this.filmShootingDetails = this.list.filmShootingDetails;
            this.memberDetails = this.list.memberDetails;
            this.idProof = this.list.idProof;
            this.Preshootfile = this.list.Preshootfile;
            this.reqLetter = this.list.reqLetter;
            this.participantfile = this.list.participantfile;
            this.leaderIdProof = this.list.leaderIdProof;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
  },
};
</script>
  
  
<style>
.subheading {
  font-family: mainfont;
  color: #42275a;
  text-transform: none;
  font-size: 17px;
  font-weight: bold;
}

.heading {
  font-family: mainfont;
  color: white;
  text-transform: none;
  font-size: 22px;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>